
















































































































































































































































































































































































import {
  Component,
  Emit,
  Mixins,
  Watch,
} from 'vue-property-decorator';

import _ from 'lodash';

import { maxLength } from 'vuelidate/lib/validators';
import { validationMixin, Validation } from 'vuelidate';

import ValidationErrors from '@/mixins/ValidationErrors.vue';

import {
  phoneNumber, shortDate,
} from '@/validations';
import { Route } from 'vue-router';

import User from '@/entities/User';
import Lender from '@/entities/Lender';

import UserService from '@/services/users';
import LenderService from '@/services/lenders';

import ControlList from '@/components/collections/ControlList.vue';

import vuelidateToPatch, {
  JsonPatchPayload,
  JsonPatchOperator,
} from '@/helpers/vuelidateToPatch';

import PreventDirtyLeave from '@/mixins/PreventDirtyLeave.vue';

const validations: any = {
  user: {
    prefix: { maxLength: maxLength(10) },
    givenName: {},
    familyName: {},
    email: {},
    phone: { phoneNumber },
    phoneExt: {},
    fax: { phoneNumber },
    hours: {},
    birthday: { shortDate },
    socialMedia: {},
    aboutMe: {},
    hobbies: {},
  },
};

@Component({
  name: 'profile',
  validations,
  async beforeRouteEnter(to: Route, from: Route, next: Function) {
    const userService: UserService = new UserService();
    const response = await userService.getOwnUser();

    if (response) {
      next((vm: Profile) => {
        vm.user = response;
        vm.loading = false;
      });
    } else {
      next({ ...new Error('No user found.'), intendedRoute: to });
    }
  },
  components: {
    ControlList,
  },
})
export default class Profile extends Mixins(
  validationMixin,
  ValidationErrors,
  PreventDirtyLeave,
) {
  private userService: UserService = new UserService();
  private lenderService: LenderService = new LenderService();

  private userSearchDialog: boolean = false;
  private searchText: string = '';

  private user?: User = null;

  private users: User[] = [];
  private userResults: User[] = [];
  private currentDebounce: Function = null;
  private currentSearch = '';

  private loading: boolean = true;
  private editMode: boolean = false;
  private readOnly: boolean = false;

  private pictureUrl: string = `${this.$baseUrl}/users/me/files/profile`;
  private coverUrl: string = `${this.$baseUrl}/users/me/files/cover`;

  private showPictureUploadDialog: boolean = false;
  private pictureUploadTarget: 'profile' | 'cover' | '' = '';
  private pictureFile: File = null;
  private rawPicture: string = null;
  private croppedPicture: File = null;
  private aspectRatios: { [index: string]: number, profile: number, cover: number } = {
    profile: 1.0,
    cover: 4.0,
  };

  private lenders: Lender[] = [];

  // Watchers
  @Watch('$route.params.id')
  async onIdChanged(id: string) {
    this.reloadUser(true);
  }

  @Watch('showPictureUploadDialog')
  async onDialogVisibilityUpdated(dialogShown: boolean) {
    if (!dialogShown) {
      this.pictureFile = null;
      this.rawPicture = null;
      this.croppedPicture = null;
    }
  }

  // Computed
  get currentRatio(): number {
    if (this.$refs.cropper) {
      (this.$refs.cropper as any).reset();
    }

    return this.aspectRatios[this.pictureUploadTarget];
  }

  // Hooks
  async created() {
    console.log('Created user profile')
    this.reloadUser(true);
    this.userService.getUsers()
      .then((users) => {
        this.users = users;
      });
  }

  async onUserChanged(user: User): Promise<void> {
    if (!user) return;

    await Promise.all(
      user.lenders.map((lender) => this.lenderService.getLender(lender.lenderId)),
    ).then((lenders) => {
      this.lenders = lenders;
    });
  }

  searchFieldUpdated(search: any) {
    this.currentSearch = search;
    if (this.currentSearch.length > 2) {
      if (!this.currentDebounce) {
        this.currentDebounce = _.debounce(this.filterUsers, 500);
        this.filterUsers();
      }
      this.currentDebounce();
    }
  }

  filterUsers() {
    // this.userResults = this.users.filter(user => ([user.givenName, user.familyName, user.email].join(" ").toUpperCase().includes(this.currentSearch.toUpperCase())));
    this.userResults = this.users.filter((user) => (user.givenName.toUpperCase().includes(this.currentSearch.toUpperCase()) || user.email.toUpperCase().includes(this.currentSearch.toUpperCase()) || user.familyName.toUpperCase().includes(this.currentSearch.toUpperCase())));
  }

  redirectUserProfile(user: User) {
    // window.open(`/profile/${user.id}`, '_blank');
    this.$router.push({ path: `/profile/${user.id}` });
  }

  determineName(user: User) {
    return `${user.givenName} ${user.familyName} (${user.email})`;
  }

  async reloadUser(loading: boolean = false) {
    this.loading = loading;
    if (this.$route.params.id) {
      this.user = await this.userService.getUserById(this.$route.params.id);
      this.pictureUrl = `${this.$baseUrl}/users/${this.$route.params.id}/files/profile`;
      this.coverUrl = `${this.$baseUrl}/users/${this.$route.params.id}/files/cover`;
      this.readOnly = true;
    } else {
      this.user = await this.userService.getOwnUser();
    }
    await this.onUserChanged(this.user);
    this.loading = false;
  }

  toggleEditMode() {
    if (this.editMode === null) return;

    this.editMode = !this.editMode;

    if (!this.editMode) {
      this.updateUser();
    }
  }

  getLenderLink(lender: Lender) {
    return `/lenders/${lender.lenderId}`;
  }

  getLenderPicture(lender: Lender) {
    return `${this.$baseUrl}/lenders/${lender.lenderId}/files/profile`;
  }

  async uploadPicture() {
    await this.cropImage();
    if (this.pictureUploadTarget === 'profile') {
      await this.userService.uploadProfilePic(this.croppedPicture);
      this.pictureUrl = `${this.pictureUrl}?date=${Date.now()}`;
    } else {
      await this.userService.uploadCoverPic(this.croppedPicture);
      this.coverUrl = `${this.coverUrl}?date=${Date.now()}`;
    }

    this.pictureFile = null;
    this.showPictureUploadDialog = false;
  }

  setImage(file: File) {
    if (file.type.indexOf('image/') === -1) {
      alert('Please select an image file');
      return;
    }
    if (typeof FileReader === 'function') {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.rawPicture = (event.target as FileReader).result as string;
        (this.$refs.cropper as any).replace((event.target as FileReader).result);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Sorry, FileReader API not supported');
    }

    this.$forceUpdate();
  }

  cropImage() {
    return new Promise<void>((resolve, reject) => {
      (this.$refs.cropper as any).getCroppedCanvas().toBlob((blob: Blob) => {
        this.croppedPicture = new File([blob], this.pictureFile.name);
        resolve();
      }, 'image/jpeg', '1.0');
    });
  }

  getUserProfilePic(userId: string) {
    return `${this.$baseUrl}/users/${userId}/files/profile`;
  }

  @Emit('update')
  async updateUser() {
    if (!this.$v.user) {
      console.log('No validation object.');
      return;
    }

    if (this.$v.user.$invalid) {
      // TODO: Interruptive whoopsie
      console.log('Invalid.');
      return;
    }

    try {
      const payload: JsonPatchPayload = vuelidateToPatch(
        this.$v.user,
      );

      if (payload.length === 0) {
        // TODO: Renotify that no changes were made?
        return;
      }

      await this.userService.patchOwnUser(payload);

      this.$v.$reset();
      this.$forceUpdate();
    } catch (e) {
      // TODO: Throw up a modal
      console.log(e);
    }
  }
}
